<template>
    <div>
        <video id="test1" width="510" height="290" autoplay></video>
    </div>
</template>
  
<script>

import { onMounted, reactive, toRefs } from 'vue'
import kurentoUtils from 'kurento-utils'
import io from 'socket.io-client'

export default {
    name: 'Test',
    components: { },
    setup() {
        const state = reactive({
            // Local 변수
            droneName: '',
            remoteVideo: '',
            roomId: 'test1',

            // Kurento 변수
            wsKurento: '',
            webRtcPeer: ''
        })

        onMounted(() => {
            state.remoteVideo = document.getElementById('test1')

            const socket = io('https://data.iotocean.org:7719')

            socket.on("connect", () => {
                console.log('Connected to server')
            })

            socket.on('subscribed', ({ sdpAnswer }) => {
                console.log('Subscribed')

                // SDP Answer 적용
                state.webRtcPeer.processAnswer(sdpAnswer)
            })

            // 방 참여 오류 처리
            socket.on('roomJoiningError', () => {
                console.error('Error joining the room')
            })

            socket.on('subscribingError', () => {
                console.error('Error subscribing')
            })

            socket.on('iceCandidate', ({ candidate }) => {
                state.webRtcPeer.addIceCandidate(candidate)
            })

            socket.on('roomJoined', () => {
                // 방에 연결되면 subscribe 시작
                startView()
            })

            state.wsKurento = socket
            joinRoom()
        })

        // Kurento Subscribe 관련 함수
        const joinRoom = () => {
            const message = state.roomId

            state.wsKurento.emit('joinRoom', message)
        }

        const startView = () => {
            try {
                const options = {
                    remoteVideo: state.remoteVideo,
                    onicecandidate: onIceCandidate
                }
                state.webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, error => {
                    if (error) throw new Error(`start WebRtcPeerRecvonly error`, error)
                    state.webRtcPeer.generateOffer(onOfferViewer)
                })
            } catch (error) {
                throw new Error(`start subscribe error`, error)
            }
        }

        const onIceCandidate = candidate => {
            const message = {
                roomId: state.roomId,
                current: 'viewer',
                candidate: candidate
            }
            console.log('socket emit onIceCandidate: ', message)
            state.wsKurento.emit('iceCandidate', message)
        }

        const onOfferViewer = (error, offerSdp) => {
            if (error) throw new Error(`Error generating SDP Offer:`, error)

            const message = { roomId: state.roomId, sdpOffer: offerSdp }
            state.wsKurento.emit('subscribe', message)
        }


        return {
            ...toRefs(state)
        }
    }
}
</script>

<style lang="scss">

</style>
  